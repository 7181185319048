.details {
	position: absolute;
	top: 150px;
	right: 10px;
	padding: 20px 10px;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	width: 300px;
	height: 550px;

	background: #fff;
	border-radius: 5px;

	z-index: 3;
}

#toggle-details-activity {
	width: 100%;
	height: 40px;

	margin-top: 5px;
	margin-bottom: 5px;

	background: #ffffff;

	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
}

#toggle-details-activity button {
	width: 130px;
	height: 100%;

	border: none;
	background: none;
	cursor: pointer;

	font-size: 16px;
	font-weight: 500;

	font-size: 18px;

	color: #6a6a6a;
}

.toggle {
	display: flex;
}

#details,
#activity {
	margin: auto;
	margin-top: 10px;

	width: calc(100% - 30px);
	height: 100%;
	border-radius: 5px;

	padding: 15px;

	background: rgba(0, 0, 0, 0.02);
	overflow-y: scroll;
	overflow-x: scroll;
}

.detail-info,
.activity-info {
	margin-top: 5px;
	font-weight: 300;
	color: gray;
}

.detail-heading,
.activity-heading {
	margin-top: 10px;
	font-weight: 500;

	font-size: 18px;
}

.activity-heading {
	font-size: 16px;
}

.activity-info {
	font-size: 13px;
}

#close-details {
	position: absolute;
	top: 20px;
	right: 20px;

	width: 20px;
	cursor: pointer;
}

.active-button {
	color: black;
	border-bottom: solid 2px #000;
}

@media (max-width: 768px) {
	.details {
		position: fixed;
		top: 0px;
		right: 0px;

		border-radius: 0px;
		height: 100%;
		overflow-y: scroll;
		overflow-x: scroll;
	}
}
