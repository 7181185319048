#message {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;

	position: absolute;
	top: 90px;
	right: 30px;

	border-radius: 6px;

	z-index: 3;

	padding: 5px 5px 10px 10px;

	width: calc(100% - 40px);
	max-width: 300px;
	height: 40px;

	border-style: solid;
	border-width: 1px;
	border-radius: 5px;
	border-color: transparent;

	color: rgb(50, 50, 50);
	background: white;

	font-size: 13px;

	backdrop-filter: blur(5px);

	animation: message-appear 0.5s;
}

#message-text {
	word-break: break-all;
	word-wrap: break-word;

	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

@keyframes message-appear {
	0% {
		right: -100vw;
	}
	100% {
		right: 30px;
	}
}

#message-icon {
	width: 17px;
	height: 17px;

	border-radius: 50%;
	cursor: pointer;
	padding: 1px;
}

#message-close {
	width: 20px;
	height: 20px;

	border-radius: 50%;
	cursor: pointer;
	padding: 1px;

	opacity: 0.5;

	margin-left: auto;
}

@media (max-width: 768px) {
	#message {
		font-size: 13px;
		width: calc(100% - 20px);
		max-width: 300px;

		left: 50%;
		top: 20px;

		transform: translateX(-50%);
	}
}
