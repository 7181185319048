.profile {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 20px;

	position: fixed;
	padding: 10px;
	border-radius: 5px;

	font-size: 13px;

	gap: 5px;

	z-index: 2;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(15px);

	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}
