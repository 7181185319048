#nav {
	background: rgb(50, 50, 50);
	color: #ffffff;

	height: 60px;
	width: 100vw;
	font-size: 15px;
	font-weight: 300;

	display: flex;
	justify-content: space-between;
	align-items: center;

	z-index: 3;
}

#nav-logo,
.nav-links {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	list-style-type: none;
	margin: 0px 30px;

	z-index: 3;
}

#nav img {
	width: 25px;
	height: 25px;
	cursor: pointer;
}

#nav a {
	color: inherit;
	text-decoration: none;
}

#avatar {
	user-select: none;
	width: 30px;
	height: 30px;
	border-radius: 50%;

	background: rgb(25, 25, 25);
	color: rgb(246, 246, 246);
	font-size: 20px;
	font-weight: 400;

	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	border-style: solid;
	border-width: 2px;
	border-color: transparent;
}

#nav li {
	margin: 0px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}

.menu-icon {
	display: none;
}

.nav-actions {
	width: 150px;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 10px;

	color: #fff;
	background: rgba(50, 50, 50, 0.9);
	backdrop-filter: blur(10px);

	position: fixed;
	top: 70px;
	right: 20px;

	padding: 10px 0px;

	z-index: 3;

	border-radius: 5px;
	list-style-type: none;

	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.nav-actions li {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: calc(100% - 40px);
	padding: 0px 10px;
	height: 30px;

	border-radius: 5px;
}

.nav-actions li:hover {
	background: rgb(60, 60, 60);
	cursor: pointer;
}

.nav-actions a {
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}

@media (max-width: 768px) {
	#nav {
		color: #323232;
		background: #fff;

		border-bottom: none;

		width: 100vw;

		padding: 0px;

		justify-content: flex-end;
	}

	.menu-icon {
		display: block;

		position: absolute;
		top: 17px;
		left: 17px;
	}

	.nav-links {
		font-size: 16px;

		position: fixed;
		top: 60px;
		left: -10px;

		padding: 10px 10px 10px 0px;

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		gap: 10px;

		color: transparent;
		box-shadow: none;
		transform: translateX(-100vw);

		width: 200px;
		z-index: 3;

		transition-duration: 0.2s;
	}

	.nav-links li {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 5px 10px;
	}

	.nav-img {
		filter: invert(1);
	}

	.toggle-menu {
		transition-duration: 0.2s;
		transform: translateX(0px);
		color: black;

		background: rgba(255, 255, 255, 0.9);
		backdrop-filter: blur(10px);

		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

		border-radius: 5px;

		width: auto;
		padding: 0px;
		padding-top: 10px;
	}

	.nav-actions {
		position: relative;
		top: 0px;
		left: 0px;

		box-shadow: none;
		width: calc(100% + 10px);

		z-index: 3;

		width: auto;

		width: 100%;
	}

	#profile img {
		width: 15px;
		height: 15px;
	}
}
