#shared-link {
	width: calc(100% - 40px);
	max-width: 500px;

	padding: 10px;

	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

	margin: auto;
	margin-top: 100px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

#share-link-url {
	width: calc(100% - 10px);
	height: 20px;

	padding: 5px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	background-color: #f5f5f5;
	border-radius: 2px;
}

#url {
	width: calc(100% - 40px);
	height: 100%;

	overflow-wrap: normal;
	white-space: nowrap;
	overflow-x: scroll;

	user-select: all;
}

#copy-link {
	border: none;
	outline: 0;
	background: inherit;
	cursor: pointer;

	margin: auto;
}

#share-link-heading {
	font-size: 20px;
	color: #343434;

	margin: 10px;
	width: calc(100% - 20px);

	display: flex;
	justify-content: space-between;
	align-items: center;
}

#share-link-url {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.warning-heading {
	font-size: 18px;
	color: #de0000;
}

.warning {
	width: calc(100% - 40px);

	margin: auto;
	margin-top: 0px;
	margin-bottom: 0px;

	color: #de0000;

	list-style-type: decimal;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	gap: 5px;
}

#access {
	width: 100%;
	height: 20px;
	padding: 5px;

	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
}

#access input[type="checkbox"] {
	width: 15px;
	height: 15px;

	cursor: pointer;
}

@media (max-width: 768px) {
	#shared-link {
		box-shadow: none;

		max-width: none;
		width: calc(100% - 30px);
		height: calc(100vh - 20px);

		margin: auto;
		margin-top: 0px;
	}
}
