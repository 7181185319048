#move-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

	background: #fff;

	border-radius: 20px;
	width: calc(100vw - 80px);
	max-width: 800px;

	height: calc(100vh - 150px);
	max-height: 800px;

	padding: 20px;

	position: fixed;
	left: 50%;
	top: 70px;

	transform: translate(-50%, 0%);
}

#move-item-directories {
	list-style-type: none;
	padding: 0;

	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;

	width: 100%;
	max-height: 750px;

	overflow-y: scroll;
}

#move-item-directories li {
	margin-top: 10px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;

	gap: 5px;
	width: 80px;

	padding: 3px;

	background: inherit;

	border-style: solid;
	border-width: 0.5px;
	border-color: transparent;

	font-size: 13px;
}

#move-item-directories li:hover {
	background: rgba(230, 230, 230, 0.1);
	cursor: pointer;

	border-style: solid;
	border-width: 0.5px;
	border-color: rgba(230, 230, 230, 0.5);
}

#move-item-navigation {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	gap: 5px;
	width: 100%;

	list-style-type: none;

	margin-top: 5px;
}

#move-item-navigation li {
	display: flex;
	align-items: center;
	justify-content: space-between;

	cursor: pointer;

	font-size: 13px;
	padding: 2px;
	border-radius: 3px;
}

#move-item-navigation li:hover {
	background: rgba(230, 230, 230, 0.2);
}

#confirm-move {
	padding: 5px 10px;
	border-radius: 5px;
	border-width: 0.5px;
	border-color: rgba(0, 0, 0, 0.2);
	border-style: solid;

	color: rgb(255, 255, 255);
	background: #1d356a;

	outline: 0;
	position: absolute;
	bottom: 15px;
	left: 15px;

	cursor: pointer;
}

#move-here {
	width: 100%;
	height: 30px;

	margin-top: 20px;

	display: flex;
	justify-content: center;
	align-items: center;

	color: rgb(49, 49, 49);

	border-style: dotted;
	border-radius: 4px;
	border-width: 1px;
	border-color: #e5e5e5;
}

@media (max-width: 768px) {
	#move-item {
		border-radius: 3px;
	}
}
