.user {
	cursor: pointer;
}

#sharing-heading {
	font-size: 20px;
	width: calc(100% - 45px);
	margin-left: auto;
	padding: 10px 0px;
	margin-top: 20px;
	color: rgb(0, 0, 0);
}

.sharing-user-workspace-container {
	width: 100vw;
	max-height: 700px;
	overflow-y: scroll;
	margin-bottom: 40px;
}

.sharing-user-workspace {
	border-collapse: collapse;
	width: calc(100% - 40px);
	margin: auto;
	min-width: 500px;
	overflow-x: scroll;
}

.sharing-user-workspace th,
.sharing-user-workspace td {
	line-height: 30.5px;
	padding: 0px 5px;
	font-size: 13px;
}

.sharing-user-workspace th:nth-child(1),
.sharing-user-workspace td:nth-child(1) {
	padding-left: 45px;
}

.sharing-user-workspace th {
	font-weight: 500;
}

.sharing-user-workspace tr {
	border-bottom: 0.5px solid #00000050;
	transition-duration: 0.3s;
	min-height: 45px;
	max-height: 45px;
	height: 45px;
}

.other-info {
	color: rgb(72, 72, 72);
	font-size: 12px;
}

.email {
	color: black;
	font-weight: 500;
	font-size: 14px;
}

.sharing-user-workspace tr:hover {
	background: #f9f9f9;
}

.loading-state {
	width: 100%;
	height: 50px;
	background: inherit;
	overflow: hidden;
	border-bottom: 0.5px solid #d0d0d0;
}

.loader {
	width: 50%;
	height: 100%;
	padding: 10px;
	background: linear-gradient(45deg, #fff, rgb(245, 245, 245), #fff);
	animation: loadingAnimation 1s infinite;
}
