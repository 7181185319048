.actions {
	font-size: 16px;

	width: 250px;

	background: rgba(255, 255, 255, 0.8);

	backdrop-filter: blur(10px);

	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	position: absolute;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	padding: 15px 0px;
	z-index: 1;
}

.actions > li {
	cursor: pointer;

	width: calc(100% - 40px);
	height: 30px;

	padding: 0px 20px;
	margin-top: 5px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 20px;
}

.actions > li:hover,
.share-list > li:hover {
	background: rgba(230, 230, 230, 0.4);
}

.actions-hr {
	width: calc(100% - 20px);
	margin: auto;
	border: 0.5px solid #d6d6d6;
	margin-top: 5px;
	margin-bottom: 5px;
}

.share-list {
	font-size: 16px;

	max-height: 350px;
	width: 250px;

	background: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(10px);

	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	position: absolute;
	top: 100px;
	left: 100px;

	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	z-index: 2;

	overflow-x: hidden;
	overflow-y: scroll;
	padding-bottom: 10px;
}

.share-list > li {
	cursor: pointer;

	width: calc(100% - 40px);
	height: 30px;

	margin-top: 5px;
	padding: 2px 20px;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.share-list > li:nth-child(1) {
	padding: 0px 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	cursor: text;
}

#search-users {
	width: calc(100% - 40px);

	border-radius: 4px;

	margin: auto;
	margin-top: 10px;

	height: 30px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	background: rgba(0, 0, 0, 0.15);
}

#search-users > input {
	width: calc(100% - 30px);
	height: 20px;

	border: none;
	outline: none;

	background: transparent;
	padding-left: 10px;
	font-size: 13px;
}

#search-users > input::placeholder {
	color: #a6a6a6;
}

.faded {
	color: gray;
}

#rename-file,
#rename-folder {
	border-radius: 4px;

	padding: 5px 10px;

	background: inherit;

	border-width: 0.5px;
	border-color: #a7a7a7;
	border-style: solid;

	outline: none;

	width: 100%;
}

.move-file-list {
	list-style-type: none;
	font-size: 16px;
	padding: 15px 0px;

	width: 250px;
	max-height: 300px;

	background: inherit;
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	display: none;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	position: fixed;
	top: 100px;
	left: 100px;

	z-index: 2;

	overflow-x: hidden;
	overflow-y: scroll;
}

.move-file-list > li {
	cursor: pointer;

	width: calc(100% - 40px);
	min-height: 30px;
	height: 30px;

	padding: 0px 20px;
	margin-top: 5px;

	display: flex;
	align-items: space-between;
	justify-content: flex-start;
	gap: 20px;
}

.toggle-actions {
	display: flex;
}

@media (max-width: 768px) {
	.actions {
		width: 200px;
		transform: translateX(40px);
		padding: 5px 0px;
		align-items: center;
	}

	.actions li {
		font-size: 14px;

		width: calc(100% - 20px);
		padding: 0px 10px;
		margin: 0px;
	}

	.actions img {
		width: 15px;
	}

	.share-list {
		width: 200px;
	}
}

@media (max-width: 500px) {
	.actions {
		width: 150px;
		transform: translateX(100px);
		padding: 2px 0px;
		align-items: center;
	}

	.actions li {
		font-size: 12px;

		width: calc(100% - 20px);
		padding: 0px 10px;
		margin: 0px;
	}

	.actions img {
		width: 14px;
	}

	.share-list {
		width: 150px;
	}
}
