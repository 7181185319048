#login-form {
	width: calc(100% - 20px);
	max-width: 400px;

	margin: auto;
	margin-top: 200px;

	padding: 10px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

#login-logo {
	font-size: 20px;

	font-family: "Contrail One", sans-serif;
	display: flex;
	align-items: center;
	justify-content: center;

	color: #737373;
	font-weight: 400;
	text-decoration: none;

	margin-bottom: 20px;
}

#login-form > h1 {
	font-weight: 400;
	font-size: 30px;

	margin-bottom: 20px;
}

#forgot-password {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin: 5px 0px 15px 0px;
}

#forgot-password > a {
	font-size: 15px;
	color: black;
	text-decoration: none;
}

#login-form > input {
	width: 100%;
	height: 40px;
	margin-bottom: 10px;

	border: 1px solid #797979;
	border-radius: 8px;
	padding: 6px 20px;

	font-size: 16px;

	box-sizing: border-box;
	outline: none;

	margin-top: 10px;
}

#login-form > input:focus {
	border: 1px solid #527dd3;
}

#login-form > input::placeholder {
	font-family: "Roboto", sans-serif;
	color: #969696;
	font-size: 16px;
	font-weight: 300;
}

#login-form > button {
	width: 100%;
	height: 40px;
	background-color: #527dd3;
	color: white;
	border: none;
	padding: 6px 20px;
	border-radius: 8px;
	font-size: 14px;
	cursor: pointer;

	margin-bottom: 10px;

	border: 1px solid #797979;
}
