.player-container {
	width: 100vw;
	height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;

	background: rgba(53, 70, 96, 0.9);

	position: fixed;
	top: 0;
	left: 0;

	backdrop-filter: blur(20px);

	z-index: 3;
}

#preview-image {
	width: calc(100vmin - 20px);

	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

#preview-close-icon {
	position: fixed;
	top: 20px;
	right: 20px;

	cursor: pointer;

	background: rgba(255, 255, 255, 0.5);
	border-radius: 50%;
}

#preview-close-icon:hover {
	background: rgba(255, 255, 255, 0.7);
}
