#users {
	width: calc(100% - 40px);
	height: 100vh;
	overflow-y: scroll;

	padding: 20px;

	background: #ffffff;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

#users h1 {
	font-size: 24px;
	color: #333;
	margin-bottom: 20px;
	text-align: center;

	margin-left: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

#users ul {
	list-style-type: none;
	padding: 0;
	max-width: 700px;
	width: calc(100%);
}

#users li {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;

	border-bottom: 2px solid rgb(129, 129, 129);
	margin-bottom: 10px;
	transition: box-shadow 0.3s ease;

	background: #fff;
	color: rgb(91, 91, 91);
}

#users li:hover {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

#users p {
	margin: 0;
}

#users div {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

#users span {
	margin-right: 10px;
}

#users img {
	width: 20px;
	height: 20px;
	cursor: pointer;
	transition: transform 0.3s ease;

	padding: 2px 10px;
	border-radius: 3px;
}

#users img:hover {
	background: #22baed;
	transform: scale(1.1);
	filter: invert(1);
}

#delete-users {
	color: #fff;

	outline: 0;
	border: none;
	border-radius: 5x;

	cursor: pointer;
	padding: 10px 20px;

	background: rgb(154, 78, 38);
}

.selected-for-deletion {
	filter: invert(1);

	background: #22baed;
	transform: scale(1.1);
}
