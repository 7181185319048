@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Contrail+One&display=swap");

* {
	margin: 0;
	padding: 0;
	user-select: none;
	scrollbar-width: none;
}

body {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-style: normal;
	background: #ffffff;
}

img {
	-webkit-user-drag: none;
}
