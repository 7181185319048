#not-found {
	width: 100vw;
	height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;

	background: #f5f5f5;
	color: black;
}

#not-found ul {
	list-style-type: none;

	border-left-style: solid;
	border-left-width: 3px;

	padding-left: 10px;
}

#not-found li {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
}

#not-found a {
	color: inherit;
}

@media (max-width: 768px) {
	#not-found {
		flex-direction: column;
	}

	#not-found ul {
		border-style: none;
		padding-top: 10px;
		border-top-style: solid;
		border-top-width: 3px;
	}
}
